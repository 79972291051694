<template>
  <div>
    <v-card class="mb-6">
      <v-card-text class="container-content">
        <span class="mr-4">Prazos de resposta do líder:</span>

        <v-spacer />

        <div class="container-title">
          <div class="container-ball-white"></div>
          <span class="ml-2 mr-4">0 horas</span>
        </div>

        <div class="container-title">
          <div class="container-ball-warning"></div>
          <span class="ml-2 mr-4">12 horas</span>
        </div>

        <div class="container-title">
          <div class="container-ball-orange"></div>
          <span class="ml-2 mr-4">24 horas</span>
        </div>

        <div class="container-title">
          <div class="container-ball-error"></div>
          <span class="ml-2 mr-4">48 horas</span>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="mb-6">
      <v-card-text class="container-content">
        <span class="mr-4">Status da Reclamação:</span>

        <v-spacer />

        <div class="container-title">
          <div class="container-ball-emerald-green"></div>
          <span class="ml-2 mr-4">Aguardando Central</span>
        </div>

        <div class="container-title">
          <div class="container-ball-white"></div>
          <span class="ml-2 mr-4">Aguardando Líder</span>
        </div>

        <div class="container-title">
          <div class="container-ball-yellowGold"></div>
          <span class="ml-2 mr-4">Aguardando GR</span>
        </div>

        <div class="container-title">
          <div class="container-ball-info"></div>
          <span class="ml-2 mr-4">Aguardando RD</span>
        </div>

        <div class="container-title">
          <div class="container-ball-purple"></div>
          <span class="ml-2 mr-4">Aguardando Encerramento</span>
        </div>

        <div class="container-title">
          <div class="container-ball-success"></div>
          <span class="ml-2 mr-4">Finalizado</span>
        </div>

        <v-space></v-space>
      </v-card-text>
    </v-card>

    <v-card class="mb-6">
      <v-card-title class="pt-0 pb-0">
        <span> Atendimentos </span>

        <v-spacer />

        <v-text-field
          v-model="filterData"
          class="mt-6"
          label="Buscar por protocolo, cliente, cpf"
          outlined
          dense
          @keyup.enter="findFilterResult"
        />

        <v-btn
          v-show="IsACallCenterAttendance"
          color="info"
          class="ml-4"
          outlined
          @click="openModal('createAttendance')"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          Novo
        </v-btn>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="listOfFilteredItems"
        :loading="isLoading"
        loading-text="Carregando dados..."
      >
        <template v-slot:no-data>
          <span>Sem informações para exibir</span>
        </template>

        <template v-slot:item.status="{ item }">
          <v-alert
            class="badge"
            :color="getStatusColor(item)"
            text
          >
            {{ item.status ? item.status : '' }}
          </v-alert>
        </template>

        <template v-slot:item.fantasy_name="{ item }">
          <v-alert
            class="badge"
            :color="getCompanyColor(item.fantasy_name)"
            text
          >
            {{ item.fantasy_name ? item.fantasy_name : '' }}
          </v-alert>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ item.created_at ? dateFormat(item.created_at) : '' }}
        </template>

        <template v-slot:item.return_date="{ item }">
          {{ item.return_date ? dateFormat(item.return_date) : '' }}
        </template>

        <template v-slot:item.action="{ item }">
          <v-icon
            medium
            class="me-2"
            color="warning"
            @click="openModal('details', item)"
          >
            {{ icons.mdiEye }}
          </v-icon>

          <v-icon
            medium
            color="info"
            class="me-2"
            @click="openModal('history', item)"
          >
            {{ icons.mdiClipboardText }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="showModalAttendance"
      width="700px"
    >
      <ModalAttendance
        @updatedTable="updatedTable()"
        @close="showModalAttendance = false"
      ></ModalAttendance>
    </v-dialog>

    <v-dialog
      v-model="showAttendanceDetails"
      width="1000px"
    >
      <AttendanceDetails
        :key="detailsAttendance.id"
        :data="detailsAttendance"
        :user-permissions="userPermissions"
        @updatedTable="updatedTable()"
        @close="showAttendanceDetails = false"
      ></AttendanceDetails>
    </v-dialog>

    <v-dialog
      v-model="showComplaintHistory"
      width="700px"
    >
      <HistoryComplaint
        :key="detailsAttendance.randomId"
        :form-data="false"
        :data="detailsAttendance"
        :user-profile="userProfile"
        @updatedTable="updatedTable()"
        @close="showComplaintHistory = false"
      ></HistoryComplaint>
    </v-dialog>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import {
  mdiClipboardText, mdiEye, mdiFilterPlusOutline, mdiFilterRemoveOutline, mdiPlaylistPlus,
} from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import AttendanceDetails from './AttendanceDetails.vue'
import HistoryComplaint from './HistoryComplaint.vue'
import ModalAttendance from './ModalAttendance.vue'

export default {
  components: {
    ModalAttendance,
    HistoryComplaint,
    AttendanceDetails,
  },

  mixins: [formatters, messages],

  data() {
    return {
      filterData: '',
      userProfile: {},
      userPermissions: [],
      detailsAttendance: {},

      headers: [
        {
          text: 'PROTOCOLO',
          value: 'protocol',
          sortable: false,
          align: 'left',
        },
        {
          text: 'DATA DO REGISTRO',
          value: 'created_at',
          sortable: false,
          align: 'center',
        },
        {
          text: 'FILIAL',
          value: 'fantasy_name',
          sortable: true,
          align: 'center',
        },
        {
          text: 'SETOR',
          value: 'name_sectors_complaints',
          sortable: true,
          align: 'center',
        },
        {
          text: 'GESTOR',
          value: 'name_responsible',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DATA DE DEVOLUÇÃO',
          value: 'return_date',
          sortable: false,
          align: 'center',
        },
        {
          text: 'STATUS',
          value: 'status',
          sortable: true,
          align: 'center',
        },
        {
          text: 'DETALHES',
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      listOfFilteredItems: [],

      isLoading: false,
      showModalAttendance: false,
      showComplaintHistory: false,
      showAttendanceDetails: false,
      IsACallCenterAttendance: false,

      icons: {
        mdiEye,
        mdiPlaylistPlus,
        mdiClipboardText,
        mdiFilterPlusOutline,
        mdiFilterRemoveOutline,
      },
    }
  },

  watch: {
    filterData() {
      this.findFilterResult()
    },
  },

  created() {
    this.userProfile = localStorageSlim.get('userProfile', { decrypt: true })
    this.userPermissions = localStorageSlim.get('userPermissions', { decrypt: true })

    this.getItemsTable()
    this.verifyUserIsLeaderCallCenter()
  },

  methods: {
    async getItemsTable() {
      this.isLoading = true

      await axiosIns
        .get('api/v1/sgq/ric/index')
        .then(response => {
          const { data } = response.data

          this.itemsTable = data
          this.listOfFilteredItems = data
        })
        .catch(error => this.showErrorMessage(error))
        .finally(() => {
          this.isLoading = false
        })
    },

    findFilterResult() {
      const { itemsTable, filterData } = this
      const filterDataLower = filterData.toLowerCase()

      this.listOfFilteredItems = itemsTable.filter(
        ({
          name, fantasy_name: fantasyName, protocol, cpf,
        }) => {
          const nameLower = name.toLowerCase()
          const fantasyNameLower = fantasyName.toLowerCase()

          return (
            nameLower.includes(filterDataLower)
          || fantasyNameLower.includes(filterDataLower)
          || protocol.includes(filterDataLower)
          || cpf.includes(filterDataLower))
        },
      )
    },

    /**
     * Returns the color associated with a given status string.
     *
     * @param {Object} item - An object containing a `status` string and `time_difference` number.
     * @param {number} item.time_difference - The number of hours since the leader standby was opened.
     * @param {string} item.status - The color associated with the given `status` string.
     * @return {string} The color associated with the given `status` string or an empty string if not found.
     */
    getStatusColor(item = { status: '', time_difference: 0 }) {
      const { status, time_difference: openTime } = item

      const statusColorMap = {
        'AGUARDANDO RD': 'info',
        'AGUARDANDO CENTRAL': 'emeraldGreen',
        'AGUARDANDO ENCERRAMENTO': 'purple',
        'AGUARDANDO GR': 'yellowGold',
        'AGUARDANDO LÍDER': this.getStatusColorForLeaderStandby(openTime),
        FINALIZADO: 'success',
      }

      return statusColorMap[status] ?? ''
    },

    /**
     * Get the company color based on the company name.
     *
     * @param {string} companyName - The name of the company.
     * @return {string} The color associated with the company name. Returns an empty string if the company name is not found.
     */
    getCompanyColor(companyName) {
      const colorMap = {
        'MOTOCA IMPERATRIZ': 'companyYellow',
        'MOTOCA AÇAILÂNDIA': 'companyPurple',
        'MOTOCA GRAJAÚ': 'companyGreen',
      }

      return colorMap[companyName] ?? ''
    },

    /**
     * Returns a color status based on the provided open time.
     *
     * @param {number} openTime - The number of hours since the leader standby was opened.
     * @return {string} A string representing the color status. Possible values: "white", "warning", "orange", "error", or an empty string.
     */
    getStatusColorForLeaderStandby(openTime) {
      if (openTime > 0 && openTime < 12) {
        return 'white'
      }

      if (openTime >= 12 && openTime < 24) {
        return 'warning'
      }

      if (openTime >= 24 && openTime < 48) {
        return 'orange'
      }

      if (openTime >= 48) {
        return 'error'
      }

      return ''
    },

    verifyUserIsLeaderCallCenter() {
      // const regexMarketing = /COORD DE MARKETING/i
      // const regexRelationshipCenter = /central\s+de\s+relacionamento/i
      // const { occupation, sector } = this.userProfile.occupation_sector ?? { sector: { name: '' }, occupation: { name: '' } }
      // const isRelationshipCenter = regexRelationshipCenter.test(sector?.name)
      // const isMarketing = regexMarketing.test(occupation.name)

      this.IsACallCenterAttendance = this.userProfile.profile.profile === 'Central de Relacionamento'
    },

    updatedTable() {
      this.getItemsTable()
    },

    /**
     * Opens a modal based on the type parameter and sets the detailsAttendance property.
     *
     * @param {string} type - The type of modal to open.
     * @param {Object} details - Optional details to pass to the modal.
     */
    openModal(type, details = {}) {
      const modal = {
        createAttendance: () => { this.showModalAttendance = true },
        history: () => {
          this.showComplaintHistory = true
          this.detailsAttendance = details
          this.detailsAttendance.randomId = Math.random()
        },
        details: () => {
          this.showAttendanceDetails = true
          this.detailsAttendance = details
        },
      }

      if (type in modal) {
        modal[type]()

        return
      }

      console.error(`Invalid modal type: ${type}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.badge {
  margin-top: 16px;
  font-size: 13px;
  height: 40px;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  * {
    font-size: small;
  }
}

.container-title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.container-ball-orange {
  background: #ff821a;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-warning {
  background: #ffb400;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-info {
  background: #16b1ff;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-yellowGold {
  background: #FFE500;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-purple {
  background: #9155fd;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-success {
  background: #56ca00;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-error {
  background: #ff4c51;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-white {
  background: #e8f0ff;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}

.container-ball-emerald-green {
  background: #22CB84;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}
</style>
